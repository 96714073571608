<template>
    <!--<div style="height:100%;">-->
    <a-layout>
        <div class="yj-conten" style="background-color:#fff;width:100%;width:fit-content;min-height:calc(100vh - 130px)">
            <a-layout-header style="background:#fff;padding:0 20px;">
                <a-radio-group default-value="b" @change="onRadioChange">
                    <a-radio-button value="a">
                        基本信息
                    </a-radio-button>
                    <a-radio-button value="b">
                        常见问题
                    </a-radio-button>
                    <a-radio-button value="c">
                        地点信息
                    </a-radio-button>
                </a-radio-group>
            </a-layout-header>
            <a-layout-content>
                <div class="yj-conten" style="background:#fff;margin-top:0;width: calc(100% - 32px); min-width: min-content;">
                    <a-row class="yj-form">
                        <a-col :span="24">
                            <label>问题描述 :</label>
                            <a-input id="name"
                                     contextmenu="2455"
                                     v-model="search"
                                     style="width:260px" placeholder="请输入问题描述"></a-input>
                            <a-button type="primary" @click="getTableData">查询</a-button>
                            <!--<a-button>导出</a-button>-->
                            <a-button type="primary" style="float:right" @click="add();">新建问题</a-button>
                        </a-col>
                    </a-row>

                    <a-table :columns="columns"
                             rowKey="ID"
                             :data-source="tableData"
                             :pagination="pagination"
                             @change="pagerChange"
                             class="yj-table-img">

                        <template slot="operation" slot-scope="text, record">

                            <a href="#" @click="onEdit(record.ID)" style="margin-right:20px;">编辑</a>

                            <a href="#" @click="onDelete(record.ID)">删除</a>

                        </template>
                    </a-table>
                </div>
            </a-layout-content>
        </div>
    </a-layout>
    <!--<div id="components-modal-demo-position">
            <a-modal v-model="modelDisplay"
                     title="编辑"
                     centered
                     okText="id==''?'新增':'编辑'"
                     cancelText="取消"
                     width="900px"
                     height="800px"
                      @ok="updateQuestionDetail"
                     >
                <a-row class="yj-form">
                    <a-col :span="24">
                        <label>问题描述 :</label>
                        <a-textarea id="title" v-model="title"
                                    placeholder="Controlled autosize"
                                    :auto-size="{ minRows:3, maxRows: 5 }" />

                    </a-col>
                    <a-col :span="24">
                        <label>问题解答 :</label>
                        <quill-editor v-model="brief"
                                      class="editer"
                                      :options="editorOption"></quill-editor>
                    </a-col>
                </a-row>
            </a-modal>
        </div>
    </div>-->
</template>
<style type="text/css">

    #title {
        height: 200px;
    }
</style>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    //import { quillEditor } from "vue-quill-editor"; //富文本编辑器
    //import "quill/dist/quill.core.css";
    //import "quill/dist/quill.snow.css";
    //import "quill/dist/quill.bubble.css";
    import { message } from 'ant-design-vue'

    export default {
        name: "QuestionList",
        components: {
            // quillEditor
        },
        data() {
            return {
                columns: [
                    {
                        title: "问题描述",
                        dataIndex: "Title",
                        width: 250,
                        ellipsis: true,
                        //align: "center"
                    },
                    {
                        title: "创建时间",
                        width: 150,
                        customRender: (text, item) => {

                            var temp = new Date(util.TimeStamptoDateTime(item.CreateTime, 'yyyy-MM-dd hh:mm:ss'));

                            return util.formatDate3(temp);
                        }
                    },
                    {
                        title: "访问次数",
                        dataIndex: "ViewCount",
                        width: 150, align: "center"
                    },
                    {
                        title: "操作",
                        dataIndex: "operation",
                        scopedSlots: { customRender: 'operation' },
                        width: 250
                    }
                ],
                modelDisplay: false,
                search: '',
                tableData: [],
                id: "",
                title: "",
                brief: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                editorOption: {
                    theme: "snow",
                    boundary: document.body,
                    modules: {
                        toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            //["blockquote", "code-block"],
                            //[{ header: 1 }, { header: 2 }],
                            //[{ list: "ordered" }, { list: "bullet" }],
                            //[{ script: "sub" }, { script: "super" }],
                            //[{ indent: "-1" }, { indent: "+1" }],
                            //[{ direction: "rtl" }],
                            //[{ size: ["small", false, "large", "huge"] }],
                            //[{ header: [1, 2, 3, 4, 5, 6, false] }],
                            //[{ color: [] }, { background: [] }],
                            //[{ font: [] }],
                            //[{ align: [] }],
                            //["clean"],
                            //["link", "image", "video"]
                        ]
                    }
                }
            }
        },
        methods: {
            add: function () {
                var self = this;
                self.$router.push({
                    name: "ShopManage_QuestionDetail", query: {
                    }
                })
            },
            onRadioChange: function (data) {
                var self = this;
                var val = data.target.value;
                console.log(data.target);
                if (val == 'a') {
                    self.$router.push({ name: "ShopManage_BaseInfor" });
                } else if (val == 'c') {
                    self.$router.push({ name: "ShopManage_SiteList" });
                }
                console.log(data);
            },

            pagerChange: function (item) {
                var self = this;

                self.pagination.current = item.current;
                self.getTableData();
            },

            onEdit: function (data) {
                var self = this;

                self.$router.push({
                    name: "ShopManage_QuestionDetail", query: {
                        id: data
                    }
                })
                //self.getQuestionDetail(data);
            },

            onDelete: function (data) {

                var self = this;
                self.$confirm({
                    title: '确定删除该问题吗',
                    content: '',
                    okText: '是',
                    okType: 'danger',
                    cancelText: '否',
                    onOk() {
                        // console.log('OK');
                        self.deleteQuestionDetail(data);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },

            deleteQuestionDetail: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/DelShopQuestion",
                    data: {
                        ID: id,
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.getTableData();
                    }
                };
                http.Post(op);
            },

            updateQuestionDetail: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/UpdateShopQuestion",
                    data: {
                        ID: self.id,
                        Title: self.title,
                        AnswerText: self.brief,
                        CustomerID: "dsi201706150003"
                    },
                    OnSuccess: function (data) {

                        self.modelDisplay = false
                        if (data.data == "True") {
                            message.success(
                                '编辑成功',
                                2,
                            );
                        }
                    }
                };
                http.Post(op);

            },

            getQuestionDetail: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetShopQuestionDetail",
                    data: {
                        id: id
                    },
                    OnSuccess: function (data) {
                        console.log(1000);
                        console.log(data);
                        self.id = data.data.ID;
                        self.title = data.data.Title;
                        self.brief = data.data.AnswerText;
                        self.modelDisplay = true;
                    }
                };
                http.Post(op);
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetShopQuestionList",
                    data: {
                        pageIndex: self.pagination.current,
                        title: self.search
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.PageCount;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            }
        },
        mounted() {
            this.getTableData();
        },
        computed: {}

    };
</script>